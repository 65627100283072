

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Rashid Ahmad",
  title: "I'm Rashid",
  subTitle: emoji(
    "Experienced Salesforce Software Developer 🚀 with a strong expertise in building custom enterprise applications using JavaScript, Aura, LWC, and other advanced technologies, with a focus on delivering exceptional user experiences."
  ),
  resumeLink:"https://rashiddev.in/static/Rashid%20Ahmad.pdf", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/rashidahmad03",
  linkedin: "https://www.linkedin.com/in/rashidahmad03/",
  gmail: "sfdchero007@gmail.com",
  gitlab: "https://gitlab.com/rashidahmad03",
  youtube: "https://www.youtube.com/@rashidahmad5476",
  medium: "https://medium.com/@rashidahmad2135",
  stackoverflow: "",
  whatsapp:"https://wa.me/919643022135",
  display: true //
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "SALESFORCE DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive applications"
    ),
    emoji("⚡Designed and developed customized solutions on Salesforce.com (SFDC) utilizing Experience Cloud,Sales Cloud, Service Cloud, and Field Service Lightning (FSL)"),
    emoji(
      "⚡ Integration of external services like Mulesoft, AWS, and SAP"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Salesforce",
      fontAwesomeClassname: "fab fa-salesforce"
    },
    {
      skillName: "HTML",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
   
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "ReactJS",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "NodeJs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "APEX",
      fontAwesomeClassname: "fas fa-user-cog"
    },
    {
      skillName: "JAVA",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "SQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "AURA",
      fontAwesomeClassname: "fas fa-bolt"
    },
    {
      skillName: "Lightning Web Components(LWC)",
      fontAwesomeClassname:"fas fa-poo-storm"
    },
    {
      skillName: "Visualforce",
      fontAwesomeClassname: "fas fa-code"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "AKTU",
      logo: require("./assets/images/aktu.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "August 2014 - June 2018"
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Design", 
      progressPercentage: "70%" 
    },
    {
      Stack: "Backend",
      progressPercentage: "90%"
    },
    {
      Stack: "Programming",
      progressPercentage: "85%"
    }
  ],
  displayCodersrank: true 
};
const bigProjects = {
  title: "Big Projects",
  subtitle: "",
  projects: [
    {
      image: require("./assets/images/chatgpt.png"),
      projectName: "Integration of GPT Gemini (Gen AI) with Salesforce",
      projectDesc: "Integrating Chat GPT (Gemini) into Salesforce significantly enhances CRM capabilities. By leveraging advanced generative AI features, organizations can improve user interactions and automate responses. This integration streamlines processes and increases efficiency. Overall, it fosters better customer relationship management. Adopting Gemini can transform how businesses engage with their customers.",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://youtu.be/695YUcnu_8M?si=lE7-bAmboHNyK6Ly"
        },
        {
          name: "GitHub Link",
          url: "https://github.com/rashidahmad03/Gen_AI_with_Salesforce"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/cicd.png"),
      projectName: "CICD-Salesforce",
      projectDesc: "A CI/CD system automates the deployment of changes from sandbox to production, facilitating developer collaboration and maintaining a detailed history of deployments, which is crucial for Salesforce developers and architects to understand in order to effectively utilize tools like GitHub Actions and select the right DevOps solutions.",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://youtu.be/rRDTVsfZYhY?si=_jnxz-2w-ok-inxb"
        },
        {
          name: "GitHub Link",
          url: "https://github.com/rashidahmad03/CICD-Salesforce/"
        }
        //  you can add extra buttons here.
      ]
    }
    ,  {
      image: require("./assets/images/stos.jpg"),
      projectName: "Salesforce To Salesforce",
      projectDesc: "Salesforce to Salesforce simplifies record sharing and data updates between businesses using Salesforce, allowing for easy integration of lead and opportunity data, management of sharing activities, and streamlined business processes through workflow and assignment rules",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://youtu.be/VtziD6lXHLE?si=dUF2UaHj9BV-6gj1"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/googleassistant.png"),
      projectName: "Google Assistant integration with Salesforce",
      projectDesc: "Integrating Google Assistant with Salesforce through REST web services involves setting up a webhook fulfillment that allows seamless communication between the two platforms. This integration enhances user interactions by enabling voice commands to trigger actions within Salesforce.",
      footerLink: [
        {
          name: "Youtube Link",
          url: "https://youtu.be/Qxj2dt_FLUI?si=YtkRK8uX586hiVwL"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Telstra",
      companylogo: require("./assets/images/telstra.png"),
      date: "March 2021 - July 2024",
      desc: "Telstra operates in the telecommunications domain and offers different internet plans with varying speeds",
      descBullets: [
        "Developed a new plan initiation flow for agents to initiate new plans for both new and existing customers, including fixed wireless, 4G, and 5G connections",
        "Created a change plan flow, allowing agents to modify a customer's plan based on their request, including upgrading or downgrading plans and automatically applying corresponding charges to the customer."
      ]
    },
    {
      role: "Associate Application Developer",
      company: "AIG",
      companylogo: require("./assets/images/aig.png"),
      date: "",
      desc: " American International Group, Inc., also known as AIG. AIG offers personal insurance solutions to help you build a stronger connection. The Partnership Pipeline App in Salesforce provides A&H partnership development teams the ability to manage, track and report on A&H Sponsorship and Partnership deals in a standardized way."
    },
    {
      role: "Associate Application Developer",
      company: "MOODY'S ANALYTICS ",
      companylogo: require("./assets/images/Moody.png"),
      date: "",
      desc: "Moody's - credit ratings, research, tools and analysis for the global capital markets."
    }
  ]
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  display: true 
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Want to chat about a project or just say hello? All are welcome to my inbox.",
  number: "+91-9643022135",
  email_address: "sfdchero007@gmail.com"
};



const isHireable = false;

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  bigProjects,
  contactInfo,
  isHireable,
  resumeSection
};
